@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,700,700i,800,800i,900,900i&display=swap');


/*======================================================
            Common
======================================================== */
html,
body {
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  background: #fff;
  letter-spacing: 0.3px;
}

p {
  margin: 0px;
  padding: 0px;
}

b {
  font-weight: normal;
}

a,
button {
  text-decoration: none;
  color: #501d3c;
  transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -o-transition: all 300ms ease-in 0s;
  -webkit-transition: all 300ms ease-in 0s;
  -ms-transition: all 300ms ease-in 0s;
}

*:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea,
select {
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open>.dropdown-toggle.btn-primary {
  outline: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  padding: 0px;
  line-height: normal;
  font-weight: normal;
}

img {
  border: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #BEBEBE;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 300;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #BEBEBE;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 300;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #BEBEBE;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 300;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #BEBEBE;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 300;
}

/*======================================================
           login
======================================================== */
.full-width {
  float: left;
  width: 100%;
}

.full-width .login-left {
  width: 67%;
  float: left;
  min-height: calc(100vh - 30px);
  background-color: #c30e2e;
  position: relative;
}

.logo {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  text-align: center;
}

.full-width .login-right {
  width: 33%;
  float: left;
  position: relative;
  padding: 0px 49px;
  min-height: calc(100vh - 30px);
}

.full-width .login-form {
  position: absolute;
  left: 50px;
  right: 50px;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.login-form h2 {
  margin-bottom: 40px;
  text-transform: uppercase;
  font-size: 16px;
  color: #666777;
  font-weight: 600;
}

.form-group label {
  margin-bottom: 9px;
  color: #666777;
  width: 100%;
  display: inline-block;
  font-size: 9px;
  text-transform: uppercase;
}

.form-group {
  margin-bottom: 30px;
  position: relative;
}

.form-control {
  border-radius: 0px;
  height: 37px;
  border: 1px solid #cccccc;
}

.comman-modal .react-autosuggest__input {
  border-radius: 0px;
  height: 37px;
  border: 1px solid #cccccc;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #cccccc;
}

.comman-btn {
  background-color: #c30e2e;
  color: #fff;
  font-size: 14px;
  height: 48px;
  border-radius: 0px;
  border: 1px solid #c30e2e;
  text-transform: uppercase;
}

.comman-btn:hover,
.comman-btn:focus {
  background-color: #fff;
  color: #c30e2e;
  border: 1px solid #c30e2e;
}

.login-btn {
  width: 100%;
}

/*======================================================
           header
======================================================== */
header {
  background-color: #c30e2e;
  height: 80px;
  width: 100%;
  float: left;
  width: 100%;
  padding: 6px 0px;
}

.sub-header-div {
  background-color: #fff;
  padding: 15px 0px;
  width: 100%;
  float: left;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.22);
  margin-bottom: 25px;
}

.sub-header-div .comman-title {
  color: #C30E2E;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
}

.header-logo img {
  max-width: 304px;
}

.header-logo {
  display: inline-block;
  vertical-align: middle;
}

.login-user {
  display: inline-block;
  vertical-align: middle;
  float: right;
  width: 220px;
  text-align: right;
  padding: 14px 0px;
}

.login-user .user-name {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.5;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login-user a {
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  float: left;
  padding-top: 3px;
}

.comman-site-btn {
  background-color: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.22);
  font-size: 12px;
  font-weight: 300;
  color: #C30E2E;
  text-transform: uppercase;
  height: 40px;
  min-width: 121px;
  padding: 0px 15px;
}

.comman-site-btn:hover,
.comman-site-btn:focus {
  background-color: #C30E2E;
  color: #fff;
}

.comman-site-btn .glyphicon-plus {
  font-size: 12px;
}

.comman-site-btn.add-new {
  width: 145px;
  height: 37px;
  float: right;
}

.ag-react-container .comman-site-btn {
  color: #000;
  min-width: auto;
  margin-right: 12px;
  height: 32px;
  padding: 0px 8px;
  text-transform: capitalize;
}

.ag-react-container .comman-site-btn.Course-Portfolio {
  text-transform: capitalize;
}

.ag-react-container .comman-site-btn:hover,
.ag-react-container .comman-site-btn:focus {
  background-color: #fff;
  color: #000;
}

.ag-theme-material .ag-cell {
  padding: 7px 15px;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #666777;
  font-weight: 600;
}

.search-button .form-control {
  width: 300px;
  display: inline-block;
}

.search-button .btn.btn-primary.comman-site-btn {
  margin-left: 10px;
  display: inline-block;
  height: 37px;
  vertical-align: top;
}

.ag-theme-material .ag-paging-page-summary-panel .ag-icon {
  color: #C30E2E;
}

.ag-icon:before {
  font-size: 20px;
}

.ag-theme-material .ag-paging-panel>span {
  font-size: 12px;
}

.ag-theme-material .ag-cell {
  line-height: 30px;
  font-size: 12px;
  color: #666777;
}

.ag-theme-material .ag-paging-panel {
  border-top: none;
}

.bottom-action-btn .comman-site-btn {
  margin-right: 20px;
  width: 123px;
}

.bottom-action-btn {
  width: 100%;
  display: inline-block;
  padding: 30px 0px;
}

.ag-theme-material .ag-header {
  border-bottom: 1px solid #333;
}

.ag-theme-material .ag-row:last-child {
  /* border-bottom: 1px solid #333; */
}

/*======================================================
           modal
======================================================== */
.comman-modal .modal-dialog {
  width: 422px;
  margin: 0px;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.modal-dialog .modal-content {
  border-radius: 0px;
}

.modal-dialog .modal-content .modal-body {
  padding: 20px 30px 0px;
}

#add-new-applicant {}

.modal-title {
  font-size: 16px;
  color: #666777;
  text-transform: uppercase;
}

.modal-header .close {
  font-size: 20px;
  opacity: 1;
}

.modal-dialog .modal-content .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
  border-radius: 0px;
}

.modal-dialog .modal-content .btn.dropdown-toggle {
  border-radius: 0px;
  height: 37px;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover {
  color: #BEBEBE;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 300;
  line-height: 24px;
}

.dropdown.bootstrap-select .btn-default:hover {
  border-color: #ccc;
  background-color: transparent;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
  background-color: transparent !important;
  border: 1px solid #ccc;
  font-weight: 400;
}

.btn-group .btn {
  width: 166px;
}

.btn-group .btn:first-child {
  margin-right: 28px;
}

#add-institution .modal-dialog {
  width: 520px;
}

.institute {
  width: 100%;
  margin-top: 30px;
}

.institute li div {
  width: 33%;
  float: left;
  font-size: 12px;
  font-weight: 300;
  color: #666777;
  vertical-align: middle;
}

.institute li div:nth-child(2) {
  text-align: center;
}

.institute li .comman-site-btn {
  width: 51px;
  height: 30px;
  float: right;
  padding: 0px;
  min-width: auto;
}

.institute li {
  padding: 7px 0px;
  position: relative;
  width: 100%;
  display: inline-block;
}

.institute li:first-child:before {
  background-color: #e5e5e5;
  ;
  height: 1px;
  width: 113%;
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
}

.institute li:after {
  background-color: #e5e5e5;
  ;
  height: 1px;
  width: 113%;
  content: "";
  position: absolute;
  left: -30px;
  bottom: 0;
}

.full-width.full-width-comman {
  min-height: calc(100vh - 183px);
  overflow-y: hidden;
}

.form-group.search-button {
  width: 432px;
  display: inline-block;
}

#filter-text-box {
  background-image: url(../images/search-icon.png);
  background-repeat: no-repeat;
  padding-left: 40px;
  background-position: left 15px center;
}

.comman-site-btn.add-institution {
  float: right;
  height: 37px;
}

.comman-site-btn.add-course {
  height: 37px;
}

.table-header {
  background-color: #eaeaea;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 15px;
  width: 100%;
}

.table-header label {
  display: inline-block;
  vertical-align: middle;
  max-width: auto;
  padding-top: 12px;
}

.header-action {
  float: right;
}

.header-action .delete-btn {
  color: #000;
  min-width: auto;
  margin-right: 12px;
  margin-left: 10px;
}

.header-action .comman-site-btn {
  height: 37px;
}

.label-header label {
  font-size: 16px;
  font-weight: 300;
  width: auto;
  display: inline-block;
  margin: 10px 0px 0px 0px;
}

.form-group.label-header {
  display: inline-block;
  width: auto;
}

.back-btn-header {
  display: inline-block;
  width: 70px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}

.back-btn-header::after {
  content: "";
  background: #000;
  height: 12px;
  width: 1px;
  position: absolute;
  right: 17px;
  top: 1px;

}

/*.ag-theme-material{border: 1px solid #d7d7d7; margin-bottom: 30px;}*/

.degree-left {
  width: 45%;
  display: inline-block;
}

.point-right {
  width: 55%;
  background-color: #eaeaea;
  display: inline-block;
  padding: 25px 20px;
  vertical-align: top;
}

.applicant-name {
  margin-bottom: 20px;
}

.applicant-name:last-child {
  margin-bottom: 0px;
}

.point-right div {
  width: 33.33%;
  display: inline-block;
  text-align: center;
  position: relative
}

.point-right div:after {
  content: "";
  position: absolute;
  right: 0px;
  height: 45px;
  width: 1px;
  background: #d7d7d7;
  top: 0px;
}

.point-right div p {
  font-size: 10px;
  font-weight: 600;
}

.point-right div:last-child:after {
  display: none;
}

.applicant-header {
  display: inline-block;
  margin-bottom: 20px;
  width: 100%;
}

.ag-react-container select {
  width: 400px;
  padding: 5px 5px;
  line-height: 24px;
  height: 33px;
}

.comman-modal .normal-select {
  width: 100%;
  padding: 5px 5px;
  line-height: 24px;
  height: 33px;
}

select {

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}


/* arrows */

select.normal-select {
  background-image:
    linear-gradient(45deg, transparent 50%, #000 50%),
    linear-gradient(135deg, #000 50%, transparent 50%),
    linear-gradient(to right, white, white);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

select.normal-select:focus {
  background-image:
    linear-gradient(45deg, #000 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #000 50%),
    linear-gradient(to right, white, white);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

.transferred label {
  color: #00ae68;
  margin-bottom: 15px;
}

.not-transferred label {
  color: #c30e2e;
  margin-bottom: 15px;
}

.credits label {
  color: #eb9d00;
  margin-bottom: 15px;
}

.back-btn-header.pull-right {
  width: 120px;
  text-align: right;
}

.back-btn-header.pull-right::after {
  display: none;
}

.corse-table {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
}

.table-header label {
  font-size: 14px;
}

.ag-theme-material .ag-header {
  font-family: 'Montserrat', sans-serif;
}

.ag-theme-material {
  font-family: 'Montserrat', sans-serif;
}

.ag-theme-material label {
  color: #666777;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.header-action-btn {
  float: right;
}

.header-action-btn .comman-site-btn {
  margin-left: 15px;
}

.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  width: 100%;
  height: 170px;
  overflow-y: scroll;
  font-size: 12px;
  font-weight: 300;
  color: #666777;
  text-transform: capitalize;
}

.city-name {
  float: right;
}

#add-new-applicant .modal-footer {
  padding: 45px 30px;
}

#add-new-applicant .modal-dialog .modal-content .modal-body {
  padding: 45px 30px 30px;
}

.form-group.grade {
  width: 50%;
  float: left;
}

.form-group.credit {
  float: left;
  width: 40%;
  margin-left: 10%;
}

.modal-body {
  width: 100%;
  display: inline-block;
}

.modal-footer {
  width: 100%;
  display: inline-block;
}

.no-record {
  text-align: center;
  position: absolute;
  max-width: 300px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  font-size: 25px;
  font-weight: 500;
  transform: translateY(-50%);
  color: #666777;
}

span.error {
  color: red;
  font-size: 11px;
  position: absolute;
  bottom: -15px;
  width: 100%;
  left: 0px;
}

.form-group.credit-main {
  display: inline-block;
  width: 100%;
}

/*======================================================
           footer
======================================================== */
footer {
  background-color: #222333;
  padding: 10px 0px;
  height: 30px;
  width: 100%;
  display: inline-block;
}

footer span {
  width: auto;
  color: #AAABBB;
  font-size: 8px;
  font-weight: 300;
  text-transform: uppercase;
  vertical-align: top;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

body.busy-cursor:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

body.busy-cursor:after {
  content: 'Loading...';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
}

select option:disabled {
  background-color: #cccccc;
}